import { useStaticQuery, graphql } from "gatsby"
import TransitionLink from "gatsby-plugin-transition-link"
import { TweenMax } from "gsap/TweenMax"
import React, { useState } from "react"
import MenuIconTwo from "../../assets/svg/icon-menu-two.svg"
import CloseIcon from "../../assets/svg/icon-close.svg"

const MAIN_MENU_QUERY = graphql`
    query MyQuery2 {
        wpgraphql {
            menuItems(where: { location: MENU_1 }) {
                nodes {
                    menuItemId
                    url
                    label
                    cssClasses
                    parentId
                    childItems {
                      nodes {
                        menuItemId
                        url
                        label
                        parentId
                      }
                    }
                }
            }
        }
        site {
            siteMetadata {
                url
            }
        }
    }
`

const  renderSubMenuItem = (SubItem, site, closeFN) => {
    const url = SubItem.url
        .replace(site.siteMetadata.url, `/`)
        .replace("//", "/");

    return (
    <TransitionLink
        key={SubItem.menuItemId}
        to={url}
        onClick={closeFN}
        exit={{
            trigger: ({ exit, node }) => {
                window.scrollTo({
                    left: 0,
                    top: 0,
                })
                TweenMax.to(node, 0.3, {
                    autoAlpha: 0,
                })
            },
            length: 1,
            zIndex: 2,
        }}
        entry={{
            trigger: ({ entry, node }) => {
                TweenMax.fromTo(
                    node,
                    0.9,
                    {
                        autoAlpha: 0,
                    },
                    { autoAlpha: 1 }
                )
            },
            delay: 1,
            zIndex: 0,
        }}
        className={SubItem.cssClasses}
    >
        <h2>{SubItem.label}</h2>
    </TransitionLink>
    )
}

const RenderMenuItem = (menuItem, site, closeFN) => {
    const url = menuItem.url
        .replace(site.siteMetadata.url, `/`)
        .replace("//", "/");

    const [toggleSub, setToggleSub] = useState(false);

    const subOpen = (reason) => {
        setToggleSub(!toggleSub);
    };

    if(!menuItem.parentId) {
        if(menuItem.childItems.nodes.length > 0) {
            return (
                <div className="with-submenu">
                    <TransitionLink
                        key={menuItem.menuItemId}
                        to={url}
                        onClick={closeFN}
                        exit={{
                            trigger: ({ exit, node }) => {
                                window.scrollTo({
                                    left: 0,
                                    top: 0,
                                })
                                TweenMax.to(node, 0.3, {
                                    autoAlpha: 0,
                                    onComplete: () => {
                                        closeFN()
                                    },
                                })
                            },
                            length: 1,
                            zIndex: 2,
                        }}
                        entry={{
                            trigger: ({ entry, node }) => {
                                TweenMax.fromTo(
                                    node,
                                    0.9,
                                    {
                                        autoAlpha: 0,
                                    },
                                    { autoAlpha: 1 }
                                )
                            },
                            delay: 1,
                            zIndex: 0,
                        }}
                        className={menuItem.cssClasses}
                    >
                        <h2>{menuItem.label}</h2>
                    </TransitionLink>
                    <div className={`toggle_mobile ${toggleSub ? 'active' : ''}`} onClick={subOpen}></div>
                    <div className={`sub-menu  ${toggleSub ? 'active' : ''}`}>
                        {menuItem.childItems.nodes.map(subMenuItem =>
                            renderSubMenuItem(subMenuItem, site, closeFN)
                        )}
                    </div>
                </div>
            )
        } else {
            return (
                <TransitionLink
                    key={menuItem.menuItemId}
                    to={url}
                    onClick={closeFN}
                    exit={{
                        trigger: ({ exit, node }) => {
                            window.scrollTo({
                                left: 0,
                                top: 0,
                            })
                            TweenMax.to(node, 0.3, {
                                autoAlpha: 0,
                                onComplete: () => {
                                    closeFN()
                                },
                            })
                        },
                        length: 1,
                        zIndex: 2,
                    }}
                    entry={{
                        trigger: ({ entry, node }) => {
                            TweenMax.fromTo(
                                node,
                                0.9,
                                {
                                    autoAlpha: 0,
                                },
                                { autoAlpha: 1 }
                            )
                        },
                        delay: 1,
                        zIndex: 0,
                    }}
                    className={menuItem.cssClasses}
                >
                    <h2>{menuItem.label}</h2>
                </TransitionLink>
            )
        }
    }
}

const NewMainMenu = props => {
    const { wpgraphql, site } = useStaticQuery(MAIN_MENU_QUERY);
    const [toggle, setToggle] = useState(false);

    const handleClose = (reason) => {
        setToggle(!toggle);
    };

    return (
        <div className={`new-menu-wrapper ${toggle ? 'open' : ''}`} >
            <nav className="main-nav">
                {wpgraphql.menuItems.nodes.map(menuItem =>
                    RenderMenuItem(menuItem, site, handleClose)
                )}
            </nav>
            <div className="menu__icons">
                <button className="menu__icon menu__icon--open" onClick={handleClose}>
                    {/* <MenuIcon /> */}
                    <MenuIconTwo />
                </button>
                <button className="menu__icon menu__icon--close" onClick={handleClose}>
                    <CloseIcon />
                </button>
            </div>
        </div>
    )
}

export default NewMainMenu
