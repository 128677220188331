import React from "react"
import Logo from "../assets/svg/intro-logo-2.svg"

const IntroLogo = React.forwardRef((props, ref) => (
    <div ref={ref}>
        <Logo />
    </div>
))

export default IntroLogo
