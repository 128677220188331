import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import MenuItem from "./menuItem"
import Img from "gatsby-image"
import WhiteLogo from "../../assets/svg/logo-color-ALT.svg"

const MenuLogo = () => {
    const data = useStaticQuery(graphql`
        query {
            placeholderImage: file(relativePath: { eq: "obd-bg.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1500) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    return (
        <MenuItem itemNumber={5} direction={`tb`}>
            <div className="menu-logo">
                <Img
                    fluid={data.placeholderImage.childImageSharp.fluid}
                    style={{
                        position: `absolute`,
                        top: 0,
                        left: 0,
                        width: `100%`,
                        height: `100%`,
                        opacity: `0.06`,
                    }}
                />
                <div className="menu-logo__wrapper">
                    <WhiteLogo />
                </div>
            </div>
        </MenuItem>
    )
}

export default MenuLogo
