import addToMailchimp from "gatsby-plugin-mailchimp"
import Arrow from "../../assets/svg/arrow-right-white.svg"
import React from "react"

export default class FooterMailchimp extends React.Component {
    state = {
        email: null,
        formDisplay: "block",
        textDisplay: "none",
        failTextDisplay: "none",
        failText: "",
    }

    _handleChange = e => {
        this.setState({
            [`${e.target.name}`]: e.target.value,
        })
    }

    _handleSubmit = e => {
        e.preventDefault()
        addToMailchimp(this.state.email) // listFields are optional if you are only capturing the email address.
            .then(data => {
                // I recommend setting data to React state
                // but you can do whatever you want (including ignoring this `then()` altogether)
                if (data["msg"] === "Thank you for subscribing!") {
                    this.setState({
                        formDisplay: "none",
                        textDisplay: "block",
                    })
                    setTimeout(() => {
                        this.setState({
                            formDisplay: "block",
                            textDisplay: "none",
                        })
                    }, 10000)
                } else {
                    this.setState({
                        failTextDisplay: "block",
                        failText: data["msg"],
                    })
                    setTimeout(() => {
                        this.setState({
                            failTextDisplay: "none",
                            failText: "",
                        })
                    }, 7000)
                }
            })
            .catch(() => {
                // unnecessary because Mailchimp only ever
                // returns a 200 status code
                // see below for how to handle errors
            })
    }
    render() {
        return (
            <div className="footer__mailchimp">
                <h4>Stay in the know with our Newsletter</h4>
                <form
                    onSubmit={this._handleSubmit}
                    style={{ display: this.state.formDisplay }}
                >
                    <div className="textNArrow">
                        <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Your email address...."
                            onChange={this._handleChange}
                        />
                        <label>
                            <input type="submit" />
                            <Arrow />
                        </label>
                    </div>
                    <div className="line"></div>
                </form>
                <h4 style={{ display: this.state.textDisplay }}>
                    Thank you For Subscribing!
                </h4>
                <h4 style={{ display: this.state.failTextDisplay }}>
                    {this.state.failText}
                </h4>
            </div>
        )
    }
}
