import { useStaticQuery, graphql } from "gatsby"
import TransitionLink from "gatsby-plugin-transition-link"
import { TweenMax } from "gsap/TweenMax"
import React from "react"
import MenuItem from "./menuItem"

const SECONDARY_MENU_QUERY = graphql`
    query SecondaryMenuQuery {
        wpgraphql {
            menu(id: "dGVybToz") {
                menuItems {
                    nodes {
                        url
                        label
                        id
                    }
                }
            }
        }
        site {
            siteMetadata {
                url
            }
        }
    }
`

const renderMenuItem = (menuItem, site, closeFN) => {
    let url
    let isLocal = true

    if (menuItem.url.indexOf("omahabydesign") !== -1) {
        url = menuItem.url.replace(site.siteMetadata.url, ``)
    } else {
        url = menuItem.url
        isLocal = false
    }

    if (isLocal) {
        return (
            <TransitionLink
                key={menuItem.id}
                to={url}
                exit={{
                    trigger: ({ exit, node }) => {
                        window.scrollTo({
                            left: 0,
                            top: 0,
                        })
                        TweenMax.to(node, 0.3, {
                            autoAlpha: 0,
                            onComplete: () => {
                                closeFN()
                            },
                        })
                    },
                    length: 1,
                    zIndex: 2,
                }}
                entry={{
                    trigger: ({ entry, node }) => {
                        TweenMax.fromTo(
                            node,
                            0.9,
                            {
                                autoAlpha: 0,
                            },
                            { autoAlpha: 1 }
                        )
                    },
                    delay: 1,
                    zIndex: 0,
                }}
            >
                <span>{menuItem.label}</span>
            </TransitionLink>
        )
    } else {
        return (
            <a
                key={menuItem.id}
                rel="noopener noreferrer"
                href={url}
                target="_blank"
            >
                <span>{menuItem.label}</span>
            </a>
        )
    }
}

const SecondaryMenu = props => {
    const { wpgraphql, site } = useStaticQuery(SECONDARY_MENU_QUERY)

    return (
        <MenuItem itemNumber={2} direction={`lr`}>
            <div className="secondary-nav">
                <div className="secondary-nav__wrapper flex flex--column">
                    {wpgraphql.menu.menuItems.nodes.map(menuItem =>
                        renderMenuItem(menuItem, site, props.closeMenu)
                    )}
                </div>
            </div>
        </MenuItem>
    )
}

export default SecondaryMenu
