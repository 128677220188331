/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault")

var _extends2 = _interopRequireDefault(
    require("@babel/runtime/helpers/extends")
)

var _multimatch = _interopRequireDefault(require("multimatch"))

var defaultOptions = {
    color: "#663391",
    paths: ["**"],
    zIndex: "9999",
    element: ".header",
}

exports.onClientEntry = function(a, pluginOptions) {
    if (pluginOptions === void 0) {
        pluginOptions = {}
    }

    exports.onRouteUpdate = function(_ref) {
        var location = _ref.location
        // Merge default options with user defined options in `gatsby-config.js`
        var options = (0, _extends2.default)(
            {},
            defaultOptions,
            {},
            pluginOptions
        ) // Check current path with specified paths in options

        var matchedPaths = (0, _multimatch.default)(
            location.pathname,
            options.paths
        ) // Return bool if paths match

        var enableScroller = matchedPaths.length > 0 // Create indicator container and append to document body

        var node = document.createElement("div")
        node.id = "gatsby-plugin-scroll-indicator" // check if viewport already has a scroll indicator

        var indicatorPresent = document.querySelector("#" + node.id)

        setTimeout(() => {
            if (enableScroller) {
                if (!indicatorPresent) {
                    document.querySelector(".header__wrapper").appendChild(node)
                    const header = document.querySelector(".header")
                    var scrolling = false
                    var indicator = document.getElementById(node.id) // Determine width of scroll indicator

                    var getIndicatorPercentageWidth = function getIndicatorPercentageWidth(
                        currentPos,
                        totalScroll
                    ) {
                        // return Math.floor((currentPos / totalScroll) * 100)
                        return currentPos / totalScroll
                    } // Find the total height of scrolling window

                    var getScrollHeight = function getScrollHeight() {
                        // https://javascript.info/size-and-scroll-window#width-height-of-the-document
                        return Math.max(
                            document.body.scrollHeight,
                            document.documentElement.scrollHeight,
                            document.body.offsetHeight,
                            document.documentElement.offsetHeight,
                            document.body.clientHeight,
                            document.documentElement.clientHeight
                        )
                    } // Add throttled listener to update on scroll

                    window.addEventListener("scroll", function() {
                        var currentPos = window.scrollY,
                            innerHeight = window.innerHeight,
                            scrollHeight = getScrollHeight(),
                            scrollDistance = scrollHeight - innerHeight

                        if (!scrolling) {
                            window.requestAnimationFrame(function() {
                                var indicatorWidth = getIndicatorPercentageWidth(
                                    currentPos,
                                    scrollDistance
                                )
                                indicator.style.transform = `scaleX(${indicatorWidth})`

                                currentPos >= 200
                                    ? header.classList.add("slide")
                                    : header.classList.remove("slide")

                                scrolling = false
                            })
                            scrolling = true
                        }
                    })
                }
            } else {
                // Try to assign scrollIndicator if it is already attached to the DOM
                var scrollIndicator = document.querySelector(
                    "#gatsby-plugin-scroll-indicator"
                ) // If the indicator is already attached to the DOM, remove it

                if (scrollIndicator) {
                    scrollIndicator.remove()
                }
            }
        }, 500)
    }
}
