import { useStaticQuery, graphql } from "gatsby"
import TransitionLink from "gatsby-plugin-transition-link"
import { TweenMax } from "gsap/TweenMax"
import React from "react"

const SECONDARY_FOOTER_QUERY = graphql`
    query SecondaryFooterQuery {
        wpgraphql {
            menu(id: "dGVybToz") {
                menuItems {
                    nodes {
                        url
                        label
                        id
                    }
                }
            }
        }
        site {
            siteMetadata {
                url
            }
        }
    }
`

const renderMenuItem = (menuItem, site) => {
    let url
    let isLocal = true

    if (menuItem.url.indexOf("omahabydesign") !== -1) {
        url = menuItem.url.replace(site.siteMetadata.url, ``)
    } else {
        url = menuItem.url
        isLocal = false
    }

    if (isLocal) {
        return (
            <TransitionLink
                key={menuItem.id}
                to={url}
                exit={{
                    trigger: ({ exit, node }) => {
                        window.scrollTo({
                            left: 0,
                            top: 0,
                        })
                        TweenMax.to(node, 0.9, {
                            autoAlpha: 0,
                        })
                    },
                    length: 1,
                    zIndex: 2,
                }}
                entry={{
                    trigger: ({ entry, node }) => {
                        TweenMax.fromTo(
                            node,
                            0.9,
                            {
                                autoAlpha: 0,
                            },
                            { autoAlpha: 1, delay: 0.9 }
                        )
                    },
                    delay: 1,
                    zIndex: 0,
                }}
            >
                <span>{menuItem.label}</span>
            </TransitionLink>
        )
    } else {
        return (
            <a
                key={menuItem.id}
                href={url}
                rel="noopener noreferrer"
                target="_blank"
            >
                <span>{menuItem.label}</span>
            </a>
        )
    }
}

const FooterSecondary = () => {
    const { wpgraphql, site } = useStaticQuery(SECONDARY_FOOTER_QUERY)

    return (
        <div className="footer__secondary">
            <nav>
                {wpgraphql.menu &&
                    wpgraphql.menu.menuItems.nodes.map(menuItem =>
                        renderMenuItem(menuItem, site)
                    )}
            </nav>
        </div>
    )
}

export default FooterSecondary
