import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import Instagram from "../../assets/svg/instagram.svg"
import Twitter from "../../assets/svg/twitter.svg"
import Facebook from "../../assets/svg/facebook.svg"

const SOCIAL_FOOTER_QUERY = graphql`
    query SocialFooterQuery {
        wpgraphql {
            menuItems(where: { location: SOCIAL }) {
                nodes {
                    url
                    label
                    id
                }
            }
        }
    }
`

const components = {
    Twitter: Twitter,
    Instagram: Instagram,
    Facebook: Facebook,
}

const renderMenuItem = menuItem => {
    const Component = components[menuItem.label]
    return (
        <a
            key={menuItem.label}
            rel="noopener noreferrer"
            href={menuItem.url}
            target="_blank"
        >
            <Component width={24} />
        </a>
    )
}

const FooterSocial = () => {
    const { wpgraphql } = useStaticQuery(SOCIAL_FOOTER_QUERY)

    return (
        <div className="footer__social">
            <h4>Follow us</h4>
            <nav className="footer__social-wrapper">
                {wpgraphql.menuItems.nodes.map(menuItem =>
                    renderMenuItem(menuItem)
                )}
            </nav>
        </div>
    )
}

export default FooterSocial
