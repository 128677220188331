import React from "react"

const MenuItem = ({ itemNumber, children, direction }) => {
    return (
        <div
            className={`menu__item menu__item--${itemNumber}`}
            data-direction={direction}
        >
            <div className="menu__item-inner">{children}</div>
        </div>
    )
}

export default MenuItem
