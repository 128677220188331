import React, { useEffect, useState } from "react"
import { TimelineMax, Elastic, Back } from "gsap/TweenMax"
import IntroLogo from "./introLogo"
import BG from "../assets/svg/bg-2.svg"

const Intro = () => {
    const [isPlayed, setIsPlayed] = useState(false)
    const introRef = React.createRef()
    const svgRef = React.createRef()

    const logoAnimation = (words, circles) => {
        const tl = new TimelineMax({
            delay: 0.7,
            onComplete: () => {
                if (introRef.current) {
                    introRef.current.classList.add("gone")
                }
                setIsPlayed(true)
            },
        })

        tl.staggerFromTo(
            circles,
            0.8,
            { scale: 0, opacity: 0, transformOrigin: "50% 50%" },
            { scale: 1, opacity: 1, ease: Elastic.easeOut.config(1, 0.4) },
            0.25
        )
            .staggerFromTo(
                words,
                0.6,
                { yPercent: 40, opacity: 0 },
                { yPercent: 0, opacity: 1, ease: Back.easeOut.config(1.4) },
                0.1
            )
            .staggerTo(
                words,
                0.6,
                { yPercent: -40, opacity: 0, ease: Back.easeIn.config(1.4) },
                0.1,
                "+=0.3"
            )
            .staggerTo(
                circles,
                0.8,
                { scale: 0, opacity: 0, ease: Elastic.easeIn.config(1, 0.4) },
                0.15,
                "-=0.3"
            )
    }

    useEffect(() => {
        if (!isPlayed) {
            const words = svgRef.current.querySelectorAll(
                "#omaha, #by, #design"
            )
            const circles = svgRef.current.querySelectorAll(
                "#big-circle, #small-circle"
            )
            logoAnimation(words, circles)
        }
    })

    // setTimeout(() => {
    // }, 100)

    return (
        <div className="intro" ref={introRef}>
            <BG />
            <div className="intro__wrapper">
                <IntroLogo ref={svgRef} />
            </div>
        </div>
    )
}

export default Intro
