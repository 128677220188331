/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql , withPrefix } from "gatsby"
// import LocomotiveScroll from "locomotive-scroll"
import "../assets/modules.css"
import Header from "./header"
import Footer from "./footer"
import Intro from "./intro"
import "../styles/main.scss"
import Helmet from "react-helmet"

const Layout = ({ children }) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)

    return (
        <>
            <Header siteTitle={data.site.siteMetadata.title} />

            <Intro />
            <main id="main-container">{children}</main>
            <Footer />
            <Helmet>
                <script src={withPrefix('jquery.min.js')}></script>
                <script src={withPrefix('popper.min.js')}></script>
                <script src={withPrefix('bootstrap.bundle.min.js')}></script>
                <script src={withPrefix('aos.js')}></script>
                <script src={withPrefix('slick.min.js')}></script>
                <script src={withPrefix('jquery.fancybox.min.js')}></script>
                <script src={withPrefix('rellax.min.js')}></script>

                <script src={withPrefix('theme.js')} defer></script>
            </Helmet>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
