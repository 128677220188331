import TransitionLink from "gatsby-plugin-transition-link"
import React from "react"
import { TweenMax } from "gsap/TweenMax"
import Menu from "./menu"
import NewMainMenu from "./newMainMenu"
import HeaderLogo from "../../assets/svg/logo-color.svg"
import { window } from "browser-monads"

const Header = () => {
    return (
        <header className="header">
            <div className="header__wrapper">
                <TransitionLink
                    to={`/`}
                    exit={{
                        trigger: ({ exit, node }) => {
                            window.scrollTo({
                                left: 0,
                                top: 0,
                            })
                            TweenMax.to(node, 0.9, {
                                autoAlpha: 0,
                            })
                        },
                        length: 1,
                        zIndex: 2,
                    }}
                    entry={{
                        trigger: ({ entry, node }) => {
                            TweenMax.fromTo(
                                node,
                                0.9,
                                {
                                    autoAlpha: 0,
                                },
                                { autoAlpha: 1, delay: 0.9 }
                            )
                        },
                        delay: 1,
                        zIndex: 0,
                    }}
                >
                    <HeaderLogo />
                </TransitionLink>
                <NewMainMenu />
            </div>
        </header>
    )
}

export default Header
