import { Link } from "gatsby"
import React from "react"
import WhiteLogo from "../../assets/svg/logo-color-ALT.svg"
import FooterSocial from "./footerSocial"
import FooterSecondary from "./footerSecondary"
import FooterMailchimp from "./footerMailchimp"

const Footer = () => {
    return (
        <footer className="footer" data-scroll>
            <div className="footer__wrapper">
                <div className="footer__container">
                    <FooterSocial />
                    <FooterSecondary />
                </div>
                <div className="footer__container">
                    <Link to={`/`}>
                        <div className="footer__logo">
                            <WhiteLogo />
                        </div>
                    </Link>
                </div>
                <div className="footer__container"> <FooterMailchimp/></div>
               
            </div>
        </footer>
    )
}

export default Footer
