import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import Instagram from "../../assets/svg/instagram.svg"
import Twitter from "../../assets/svg/twitter.svg"
import Facebook from "../../assets/svg/facebook.svg"
import MenuItem from "./menuItem"

const SOCIAL_MENU_QUERY = graphql`
    query SocialMenuQuery {
        wpgraphql {
            menuItems(where: { location: SOCIAL }) {
                nodes {
                    url
                    label
                    id
                }
            }
        }
    }
`

const components = {
    Twitter: Twitter,
    Instagram: Instagram,
    Facebook: Facebook,
}

const renderMenuItem = menuItem => {
    const Component = components[menuItem.label]
    return (
        <a
            key={menuItem.label}
            rel="noreferrer noopener"
            href={menuItem.url}
            target="_blank"
        >
            <Component width={24} />
        </a>
    )
}

const SocialMenu = () => {
    const { wpgraphql } = useStaticQuery(SOCIAL_MENU_QUERY)

    return (
        <MenuItem itemNumber={3} direction={`lr`}>
            <div className="social-nav">
                <p>Follow us</p>
                <div className="social-nav__wrapper">
                    {wpgraphql.menuItems.nodes.map(menuItem =>
                        renderMenuItem(menuItem)
                    )}
                </div>
            </div>
        </MenuItem>
    )
}

export default SocialMenu
