import React, { Component } from "react"
import MainMenu from "./mainMenu"
import SecondaryMenu from "./secondaryMenu"
import SocialMenu from "./socialMenu"
import GetInvolved from "./getInvolved"
import MenuIcons from "./menuIcons"
import MenuLogo from "./menuLogo"
import { TweenMax, Power3, Power4, Power1 } from "gsap/TweenMax"
import { isMobile } from "../../utils/is-mobile"

class Menu extends Component {
    constructor(props) {
        super(props)
        this.el = React.createRef()
        this.menuItems = []
        this.itemsTotal = null
        this.isAnimating = false
        this.openCtrl = null
        this.closeCtrl = null
        this.mainLinks = null
        this.secondaryLinks = null
        this.socialLinks = null
        this.state = {
            open: false,
        }
    }

    open = () => {
        this.toggle("open")
        document.body.style.overflow = "hidden"
        if (isMobile) {
            document.body.style.position = "fixed"
            document.body.style.top = `-${window.scrollY}px`
        }
    }

    close = () => {
        this.toggle("close")
        document.body.style.overflow = "auto"
        if (isMobile) {
            const scrollY = document.body.style.top
            document.body.style.position = ""
            document.body.style.top = ""
            window.scrollTo(0, parseInt(scrollY || "0") * -1)
        }
    }

    toggle = action => {
        if (this.isAnimating) return

        this.isAnimating = true

        // Toggling the open state class.
        this.el.current.classList[action === "open" ? "add" : "remove"](
            "menu--open"
        )
        // After all is animated..
        const animationEnd = pos => {
            if (pos === this.itemsTotal - 1) {
                this.isAnimating = false
            }
        }

        //Going through each menu's item
        this.menuItems.forEach((el, pos) => {
            // The inner wrapper.
            const innerEl = el.querySelector(".menu__item-inner")
            // config and inner config will have the starting transform values (when opening) and the end ones (when closing) for both the item and its inner element.
            const config = {}
            const configInner = {}
            // Direction defined in the HTML data-direction.
            // bt (bottom to top) || tb (top to bottom) || lr (left to right) || rl (right to left)
            const direction = el.dataset.direction
            // Using 101% instead of 100% to avoid rendering problems.
            // In order to create the "reveal" effect, the item slides moves in one direction and its inner element in the opposite direction.
            if (direction === "bt") {
                config.y = "101%"
                configInner.y = "-101%"
                configInner.x = "0%"
            } else if (direction === "tb") {
                config.y = "-101%"
                configInner.y = "101%"
                configInner.x = "0%"
            } else if (direction === "lr") {
                config.x = "-101%"
                configInner.x = "101%"
            } else if (direction === "rl") {
                config.x = "101%"
                configInner.x = "-101%"
            } else {
                config.x = "101%"
                config.y = "101%"
                configInner.x = "-101%"
                configInner.y = "-101%"
            }

            if (action === "open") {
                // Setting the initial values.
                TweenMax.set(el, config)
                TweenMax.set(innerEl, configInner)
                // Animate.
                TweenMax.to([el, innerEl], 0.9, {
                    ease: Power4.easeOut,
                    x: "0%",
                    y: "0%",
                    onComplete: () => animationEnd(pos),
                })
            } else {
                TweenMax.to(el, 0.9, {
                    ease: Power3.easeInOut,
                    x: config.x || 0,
                    y: config.y || 0,
                })
                TweenMax.to(innerEl, 0.9, {
                    ease: Power3.easeInOut,
                    x: configInner.x || 0,
                    y: configInner.y || 0,
                    onComplete: () => animationEnd(pos),
                })
            }
        })

        // Show/Hide open and close ctrls.
        TweenMax.to(this.closeCtrl, 0.6, {
            ease: action === "open" ? Power4.easeOut : Power3.easeInOut,
            startAt: action === "open" ? { rotation: 0 } : null,
            opacity: action === "open" ? 1 : 0,
            rotation: action === "open" ? 180 : 270,
            pointerEvents: action === "open" ? "auto" : "none",
        })
        TweenMax.to(this.openCtrl, action === "open" ? 0.6 : 0.3, {
            delay: action === "open" ? 0 : 0.3,
            ease: action === "open" ? Power4.easeOut : Power1.easeOut,
            opacity: action === "open" ? 0 : 1,
            pointerEvents: action === "open" ? "none" : "auto",
        })

        // Main links animation.
        TweenMax.staggerFromTo(
            this.mainLinks,
            action === "open" ? 0.9 : 0.6,
            action === "open"
                ? { y: "50%", opacity: 0 }
                : { y: "0%", opacity: 1 },
            {
                ease: action === "open" ? Power4.easeOut : Power3.easeInOut,
                y: action === "open" ? "0%" : "50%",
                opacity: action === "open" ? 1 : 0,
            },
            action === "open" ? 0.1 : -0.1
        )

        // Secondary Menu links animation.
        TweenMax.staggerFromTo(
            this.secondaryLinks,
            action === "open" ? 0.5 : 0.5,
            action === "open" ? { y: "100%" } : { y: "0%" },
            {
                ease: action === "open" ? Power4.easeInOut : Power3.easeInOut,
                y: action === "open" ? "0%" : "100%",
            },
            action === "open" ? 0.05 : -0.05
        )

        // Social Menu links animation.
        TweenMax.staggerFromTo(
            this.socialLinks,
            action === "open" ? 0.5 : 0.5,
            action === "open"
                ? { y: "50%", opacity: 0 }
                : { y: "0%", opacity: 1 },
            {
                ease: action === "open" ? Power4.easeOut : Power3.easeInOut,
                y: action === "open" ? "0%" : "50%",
                opacity: action === "open" ? 1 : 0,
            },
            action === "open" ? 0.05 : -0.05
        )
    }

    componentDidMount() {
        this.menuItems = [...document.querySelectorAll(".menu__item")]
        this.itemsTotal = this.menuItems.length
        this.openCtrl = document.querySelector(".menu__icon--open")
        this.closeCtrl = document.querySelector(".menu__icon--close")
        this.mainLinks = [...document.querySelectorAll(".main-nav a h2")]
        this.secondaryLinks = [
            ...document.querySelectorAll(".secondary-nav a span"),
        ]
        this.socialLinks = [...document.querySelectorAll(".social-nav a")]
    }

    render() {
        return (
            <div className="menu" ref={this.el}>
                <div className="menu__wrapper">
                    <MainMenu closeMenu={this.close} />
                    <SecondaryMenu closeMenu={this.close} />
                    <GetInvolved closeMenu={this.close} />
                    <SocialMenu />
                    <MenuLogo />
                </div>
                <MenuIcons open={this.open} close={this.close} />
            </div>
        )
    }
}

export default Menu
