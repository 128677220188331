import { useStaticQuery, graphql } from "gatsby"
import TransitionLink from "gatsby-plugin-transition-link"
import { TweenMax } from "gsap/TweenMax"
import React from "react"
import MenuItem from "./menuItem"

const MAIN_MENU_QUERY = graphql`
    query MyQuery {
        wpgraphql {
            menuItems(where: { location: MENU_1 }) {
                nodes {
                    label
                    url
                    menuItemId
                }
            }
        }
        site {
            siteMetadata {
                url
            }
        }
    }
`

const renderMenuItem = (menuItem, site, closeFN) => {
    const url = menuItem.url
        .replace(site.siteMetadata.url, `/`)
        .replace("//", "/")

    return (
        <TransitionLink
            key={menuItem.menuItemId}
            to={url}
            onClick={closeFN}
            exit={{
                trigger: ({ exit, node }) => {
                    window.scrollTo({
                        left: 0,
                        top: 0,
                    })
                    TweenMax.to(node, 0.3, {
                        autoAlpha: 0,
                        onComplete: () => {
                            closeFN()
                        },
                    })
                },
                length: 1,
                zIndex: 2,
            }}
            entry={{
                trigger: ({ entry, node }) => {
                    TweenMax.fromTo(
                        node,
                        0.9,
                        {
                            autoAlpha: 0,
                        },
                        { autoAlpha: 1 }
                    )
                },
                delay: 1,
                zIndex: 0,
            }}
        >
            <h2>{menuItem.label}</h2>
        </TransitionLink>
    )
}

const Menu = props => {
    const { wpgraphql, site } = useStaticQuery(MAIN_MENU_QUERY)

    return (
        <MenuItem itemNumber={1} direction={`bt`}>
            <nav className="main-nav">
                {wpgraphql.menuItems.nodes.map(menuItem =>
                    renderMenuItem(menuItem, site, props.closeMenu)
                )}
            </nav>
        </MenuItem>
    )
}

export default Menu
