import React from "react"
import MenuIconTwo from "../../assets/svg/icon-menu-two.svg"
import CloseIcon from "../../assets/svg/icon-close.svg"

const MenuIcons = ({ open, close, openRef, closeRef }) => {
    return (
        <div className="menu__icons">
            <button className="menu__icon menu__icon--open" onClick={open}>
                {/* <MenuIcon /> */}
                <MenuIconTwo />
            </button>
            <button className="menu__icon menu__icon--close" onClick={close}>
                <CloseIcon />
            </button>
        </div>
    )
}

export default MenuIcons
