import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import MenuItem from "./menuItem"
import Img from "gatsby-image"

const GetInvolved = props => {
    const data = useStaticQuery(graphql`
        query {
            placeholderImage: file(relativePath: { eq: "obd-bg.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1500) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    return (
        <MenuItem itemNumber={4} direction={"rl"}>
            <div className="get-involved-menu">
                <Img
                    fluid={data.placeholderImage.childImageSharp.fluid}
                    style={{
                        position: `absolute`,
                        top: 0,
                        left: 0,
                        width: `100%`,
                        height: `100%`,
                        opacity: `0.06`,
                    }}
                />
                <div className="get-involved-menu__wrapper">
                    <Link
                        to={`/get-involved`}
                        className={`btn-special`}
                        onClick={props.closeMenu}
                    >
                        Get Involved
                    </Link>
                </div>
            </div>
        </MenuItem>
    )
}

export default GetInvolved
